<template>
  <div class="home">
    <!-- 头部 -->
    <my-header @outLoginFun='outLoginFun' @weightOpen="weightOpen"></my-header>
    <div>
      <router-view></router-view>
    </div>
  </div>
  <!-- 权重配置弹框 -->
  <weight-dialog :show="weightState.show" :STORAGE_KEY="STORAGE_KEY" @close="weightClose" @reDefualt="reDefualt"
    @setStorageConfig='setStorageConfig'></weight-dialog>
</template> 

<script setup>
import MyHeader from "./comp/MyHeader.vue";
import outLoginHook from "@/utils/outLoginHook.js";
import WeightDialog from "./comp//WeightDialog.vue";
import useWeight from "./comp/useWeight.js";

// 权重配置
const { STORAGE_KEY, state: weightState, open: weightOpen, close: weightClose, reDefualt, setStorageConfig } = useWeight()

// 退出登录
const { outLoginFun } = outLoginHook();

</script>

<style lang="scss" scoped>
.home {
  padding: 40px;
  padding-top: 80px;

  .row_box {
    background: #fff;
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 10px;

    .tit_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search_box {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      padding-top: 20px;

      .li {
        margin-right: 20px;
        margin-bottom: 10px;

        h6 {
          font-weight: normal;
          color: #818a9b;
          padding-bottom: 6px;
          font-size: 14px;
        }

        .el-select {
          width: 200px;
        }

        :deep(.el-input__inner) {
          border-radius: 7px;
        }

        .int_box {
          display: flex;
          align-items: center;

          .el-input {
            width: 98px;
          }
        }

        &.two_select {
          .el-select {
            width: 150px;
            margin-right: 15px;
          }
        }
      }
    }

    .btn_box {
      text-align: center;
      padding-top: 10px;
    }

    .table_box {
      position: relative;
      padding-top: 15px;

      .no_data {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-top: 180px;
      }
    }
  }

  .page_box {
    text-align: right;
    padding: 20px 0;
  }
}

:deep(.el-table__empty-text) {
  display: none;
}

// 滚动条的宽度
:deep(.el-table__body-wrapper::-webkit-scrollbar) {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}
</style>
