import { reactive } from 'vue';
import { getStorage, setStorage } from '@/utils';

export default () => {

    const STORAGE_KEY = 'weightConfigCitydistrict';// 权重配置存缓存的key
    // 配置默认值
    const configDefult = {
        weight_shop_num: 19,//餐饮门店数
        weight_avg_price: 5,//平均客单价
        weight_avg_comment_num: 10,//店均评论数
        weight_shop_num_inc_ratio: 28,//门店数增长率
        weight_avg_price_inc_ratio: 5,//平均客单价增长率
        weight_new_shop_ratio: 18,//新店率
        weight_new_dish_recommends_ratio: 5,//新菜推荐率
        weight_new_dish_ratio: 10,//新菜率
    }
    const state = reactive({
        show: false,
    })

    // 打开弹框
    function open() {
        setDefualt()
        state.show = true;
    }

    // 关闭弹框
    function close() {
        state.show = false;
    }

    // 获取缓存的值，如果不存在，设置默认值
    function setDefualt() {
        const weightConfig = getStorage(STORAGE_KEY);
        if (!weightConfig) {
            setStorageConfig(configDefult)
        }
    }

    // 将配置表单缓存到本地
    function setStorageConfig(form) {
        setStorage(STORAGE_KEY, JSON.stringify(form))
    }

    // 恢复默认值
    function reDefualt() {
        setStorageConfig(configDefult)
    }

    // 获取配置对象
    function getWeightConfig() {
        return JSON.parse(getStorage(STORAGE_KEY))
    }

    setDefualt();// 设置默认值

    return { STORAGE_KEY, state, open, close, reDefualt, getWeightConfig, setStorageConfig }
}